enum Getters {
  CURRENT_USER = 'currentUser',
  GET_ALL_CONSTANTS = 'getAllConstants',
  ALL_LANGUAGES = 'allLanguages',
  CURRENT_ROLES = 'currentRoles',
  GET_ALL_PRICEPLANS = 'getAllPriceplans',
  GET_ALL_MANAGERS = 'getAllManagers',
  ALL_ADMIN_REFLINKS = 'allAdminReflinks',
  GET_ADMIN_PAY_SYSTEMS = 'getAdminPaySystems',
  ALL_SELECT_APPS = 'allSelectApps',
  ALL_APPS = 'allApps',
  ALL_FULL_APPS = 'allFullApps',
  ALL_FULL_APPS_ANDROID = 'allFullAppsAndroid',
  ALL_FULL_APPS_IOS = 'allFullAppsIos',
  ALL_FULL_APPS_BANNED = 'allFullAppsBanned',
  ALL_FULL_APPS_FAVORITE = 'allFullAppsFavorite',
  ALL_FULL_APPS_LAST_PAGE = 'allFullAppsLastPage',
  ALL_FULL_APPS_PAGE = 'allFullAppsPage',
  ALL_FULL_APPS_COUNT = 'allFullAppsCount',
  GET_ALL_NOTICES = 'getAllNotices',
  GET_ALL_NOTIFICATIONS = 'getAllNotifications',
  GET_ADMIN_EMAIL_TEMPLATE = 'getAdminEmailTemplate',
  GET_ADMIN_PRODUCTS = 'getAdminProducts',
  ALL_ACCESS_LISTS = 'allAccessLists',
  ALL_PERMISSION_MODULES = 'allPermissionModules',
  GET_ADMIN_VENDORS_LIST = 'getAdminVendorsList',
  GET_ALL_USERS_PAYOUTS = 'getAllUsersPayouts',
  ALL_COUNTRIES = 'allCountries',
  GET_PRICEPLAN = 'getPriceplan',
  GET_ALL_NOTICE_TEMPLATES = 'getAllNoticeTemplates',
  GET_PAYMENTS = 'getPayments',
  GET_ALL_FAQS = 'getAllFAQs',
  ALL_ADMIN_MARKETING_ACTIONS = 'allAdminMarketingActions',
  ALL_SUGGEST_KEYWORD = 'allSuggestKeyword',
  ALL_KEYWORDS = 'allKeywords',

  GET_LAST_ADDED_APP = 'getLastAddedApp',
  GET_ACCESS_LISTS_CURRENT_PAGE = 'getAccessListsCurrentPage',
  GET_ACCESS_LISTS_LAST_PAGE = 'getAccessListsLastPage',
  GET_ACCESS_LISTS_PAGE = 'getAccessListsPage',
  GET_ACCESS_LISTS_ROWS = 'getAccessListsRows',
  GET_ACCESS_LISTS_TYPE = 'getAccessListsType',
  GET_ACCESS_LISTS_SEARCH = 'getAccessListsSearch',
  GET_ACCESS_LISTS_LIMIT = 'getAccessListsLimit',
  GET_TOTAL_ACCESS_LISTS = 'getTotalAccessLists',
  GET_ACCESS_LISTS_CATEGORY = 'getAccessListsCategory',
  GET_ERRORS_ACCESS_LIST = 'getErrorsAccessList',
  GET_ACCESS_LISTS_ORDER_BY = 'getAccessListsOrderBy',
  GET_ACCESS_LISTS_SORT_BY = 'getAccessListsSortBy',

  GET_REVIEWS_ADD_CAMPAIGNS = 'getReviewsAddCampaigns',
  GET_REVIEWS_COMMON_ADD_CAMPAIGNS = 'getReviewsCommonAddCampaigns',
  GET_DEEPLINK = 'getDeeplink',
  GET_CPI_COUNTRY = 'getCpiCountry',
  GET_CPI_TARGET = 'getCpiTarget',
  GET_CPI_BUDGET = 'getCpiBudget',
  GET_SERIALIZED_CHILD_KEYWORDS = 'getSerializedChildKeywords',
  GET_CPI_DAYS = 'getCpiDays',
  GET_CPI_HEADLINE_1 = 'getCpiHeadline1',
  GET_CPI_HEADLINE_2 = 'getCpiHeadline2',
  GET_CPI_AD_ASSETS = 'getCpiAdAssets',
  GET_REVIEW_MODE = 'getReviewMode',
  GET_GENDER = 'getGender',
  GET_PARTNER = 'getPartner',
  GET_CHECKED_TERMS = 'getCheckedTerms',
  GET_ADD_CAMPAIGNS = 'getAddCampaigns',
  GET_DELIVERY_TYPE = 'getDeliveryType',
  GET_RATE_TYPE = 'getRateType',
  GET_DESCRIPTION_ADD_CAMPAIGNS = 'getDescriptionAddCampaigns',
  GET_PROMOTION_RULE = 'getPromotionRule',
  GET_HQINSTALLS = 'getHQInstalls',
  GET_REVIEW_INSTALLS = 'getReviewInstalls',
  GET_RATE_INSTALLS = 'getRateInstalls',
  GET_CPI_INSTALLS = 'getCpiInstalls',
  GET_CHECKBOX_VALUES = 'getCheckboxValues',
  GET_DEFAULT_CHECKBOX_VALUES = 'getDefaultCheckboxValues',
  GET_CHECKBOX_UNLIMITED = 'getCheckboxUnlimited',

  ALERT_STATUS = 'alertStatus',
  ALERT_TYPE = 'alertType',
  ALERT_MESSAGE = 'alertMessage',
  ALERT_MILLISECONDS_TO_CLOSE = 'alertMillisecondsToClose',
  ALERT_CLOSE_ONLY_ON_CLICK = 'alertCloseOnlyOnClick',
  GET_DASHBOARD_START_UNREAD_NOTICES = 'getDashboardStartUnreadNotices',

  GET_APPS_COUNT = 'getAppsCount',
  CURRENT_APP = 'currentApp',
  CURRENT_APPSTORE = 'currentAppstore',
  CURRENT_APPMAN_APP = 'currentAppmanApp',
  ALL_SEARCH_APPS = 'allSearchApps',
  CURRENT_TOTAL = 'currentTotal',

  GET_IS_BUILD = 'getIsBuild',
  GET_USER_ROLES = 'getUserRoles',
  IS_USER_AUTHENTICATED = 'isUserAuthenticated',
  GET_REDIRECT = 'getRedirect',
  GET_VERSION = 'getVersion',
  LEGACY_BALANCE_DATA = 'legacyBalanceData',
  GET_LEGACY_BALANCE_STATUS = 'getLegacyBalanceStatus',

  GET_CLASSES = 'getClasses',

  GET_BREADCRUMBS = 'getBreadcrumbs',
  PAGE_BREADCRUMB_PATH = 'pageBreadcrumbPath',
  PAGE_TITLE = 'pageTitle',

  LAYOUT_CONFIG = 'layoutConfig',

  CURRENT_LANG = 'currentLang',
  CURRENT_COUNTRY = 'currentCountry',

  CURRENT_CHART_KEYWORD = 'currentChartKeyword',
  GET_PROMOTE_KEYWORDS = 'getPromoteKeywords',
  ALL_KEYWORD_RANK_UPDATE = 'allKeywordRankUpdate',
  CURRENT_KEYWORD = 'currentKeyword',
  GET_LAST_ADDED_CHART_KEYWORD = 'getLastAddedChartKeyword',
  GET_IS_KEYWORD_LOADING = 'getIsKeywordLoading',
  ALL_CHART_KEYWORDS = 'allChartKeywords',
  ALL_CLONED_KEYWORDS = 'allClonedKeywords',
  ALL_SERIALS_CHART = 'allSerialsChart',
  ALL_EXPORT_KEYWORDS = 'allExportKeywords',
  ALL_KEYWORDS_ADD = 'allKeywordsAdd',
  CURRENT_RANK_KEYWORD = 'currentRankKeyword',
  ALL_TOP_APPS_BY_KEYWORD = 'allTopAppsByKeyword',
  GET_ERRORS_KEYWORDS = 'getErrorsKeywords',
  GET_CURRENT_APP_ID = 'getCurrentAppId',
  GET_CURRENT_APP_KEYWORD_ID = 'getCurrentAppKeywordId',
  CURRENT_TOTAL_KEYWORD = 'currentTotalKeyword',
  CURRENT_FAV_KEYWORD_COUNT = 'currentFavKeywordCount',
  CURRENT_ALL_KEYWORD_COUNT = 'currentAllKeywordCount',
  GET_CURRENT_KEYWORD_APPSTORE = 'getCurrentKeywordAppstore',
  GET_CURRENT_COUNTRY_ID = 'getCurrentCountryId',
  GET_AR_IDS = 'getArIds',

  CURRENT_LANGUAGE = 'currentLanguage',

  GET_MANAGER = 'getManager',
  GET_UNREAD_NOTICES = 'getUnreadNotices',
  GET_CURRENT_USER_NOTICE_DATA = 'getCurrentUserNoticeData',
  GET_EMPTY_LOAD_NOTICE_DATA = 'getEmptyLoadNoticeData',
  GET_CHAT_PAGE_NOTIFICATIONS = 'getChatPageNotifications',

  GET_MARKETING_ACTION_ERROR = 'getMarketingActionError',
  GET_MARKETING_ACTION_TEXT = 'getMarketingActionText',
  GET_MARKETING_ACTION_TYPE = 'getMarketingActionType',
  GET_MARKETING_ACTION_EXISTS = 'getMarketingActionExists',
  GET_MARKETING_ACTION_THRESHOLDS = 'getMarketingActionThresholds',

  GET_CAMPAIGN_ID_TOP_FILTER = 'getCampaignIdTopFilter',
  GET_MY_CAMPAIGNS_FILTER_SHOULD_BE_RELOADED = 'getMyCampaignsFilterShouldBeReloaded',
  GET_BEFORE_CHANGE_STATUS_CAMPAIGN = 'getBeforeChangeStatusCampaign',
  GET_MY_CAMPAIGNS_COUNT = 'getMyCampaignsCount',
  GET_MY_CAMPAIGNS_QUERY_PARAMS = 'getMyCampaignsQueryParams',
  GET_MY_CAMPAIGNS_ENTITY = 'getMyCampaignsEntity',
  GET_MY_CAMPAIGNS = 'getMyCampaigns',
  GET_EXPORT_MY_CAMPAIGNS = 'getExportMyCampaigns',
  GET_TOTAL_CAMPAIGNS = 'getTotalCampaigns',
  GET_CURRENT_PAGE = 'getCurrentPage',
  GET_LAST_PAGE = 'getLastPage',
  GET_CURRENT_CAMPAIGN = 'getCurrentCampaign',
  GET_LOADED_CAMPAIGNS = 'getLoadedCampaigns',
  GET_CAMPAIGN_SHOW_ALL_ORDERS = 'getCampaignShowAllOrders',
  GET_CAMPAIGN_PARTNERS = 'getCampaignPartners',
  GET_UPDATE_CAMPAIGNS = 'getUpdateCampaigns',
  GET_MY_CAMPAIGNS_FILTERS = 'getMyCampaignsFilters',
  GET_LOADED_CPI_MODAL_IDS = 'getLoadedCpiModalIds',

  GET_USER = 'getUser',
  GET_USER_EMAIL_TEMPLATE = 'getUserEmailTemplate',
  GET_USER_EMAIL_TEMPLATE_LIST = 'getUserEmailTemplateList',

  GET_ERRORS_NOTIFICATIONS = 'getErrorsNotifications',

  GET_SELECTED_PAYSYSTEM_ID = 'getSelectedPaysystemId',
  GET_BONUS = 'getBonus',
  GET_OPTIONS = 'getOptions',
  GET_PAYMENT_CONFIGURATIONS = 'getPaymentConfigurations',
  GET_PAYMENT_LINK = 'getPaymentLink',
  GET_PAYMENT_ERROR = 'getPaymentError',

  CURRENT_PERMISSION_FUNCTION = 'currentPermissionFunction',
  ALL_PERMISSION_FUNCTIONS = 'allPermissionFunctions',
  CURRENT_PERMISSION_MODULE = 'currentPermissionModule',

  GET_ERRORS_PRICEPLANS = 'getErrorsPriceplans',
  GET_ALL_PAYMENTS = 'getAllPayments',
  GET_LIST_PAYMENTS = 'getListPayments',

  GET_ALL_USERS_REFERRALS = 'getAllUsersReferrals',
  GET_ALL_USERS_REFERRAL_TOTAL = 'getAllUsersReferralTotal',
  GET_USERS_REFERRALS_QUERY_PARAMS = 'getUsersReferralsQueryParams',
  GET_USERS_REFERRALS_EXPORT_DATA = 'getUsersReferralsExportData',
  GET_USERS_REFERRALS_LAST_PAGE = 'getUsersReferralsLastPage',
  GET_USERS_REFERRALS_ROWS_PER_PAGE = 'getUsersReferralsRowsPerPage',
  GET_USERS_REFERRALS_CURRENT_PAGE = 'getUsersReferralsCurrentPage',
  GET_USERS_REFERRALS_SEARCH = 'getUsersReferralsSearch',
  GET_USERS_REFERRALS_ORDER_BY = 'getUsersReferralsOrderBy',
  GET_USERS_REFERRALS_SORT_BY = 'getUsersReferralsSortBy',
  GET_ERRORS_USER_DETAILS_REFERRALS = 'getErrorsUserDetailsReferrals',
  GET_ALL_WIDGETS_REFERRALS = 'getAllWidgetsReferrals',

  CURRENT_ROLE = 'currentRole',
  CURRENT_TOTAL_PAGES = 'currentTotalPages',
  GET_ALL_COUNTRY_FILTERS = 'getAllCountryFilters',

  GET_COUNTRIES_STATUS = 'getCountriesStatus',
  GET_CONSTANTS_STATUS = 'getConstantsStatus',
  GET_LANGUAGES_STATUS = 'getLanguagesStatus',
  GET_COUNTRY_FILTERS_STATUS = 'getCountryFiltersStatus',
  GET_PRICEPLANS_STATUS = 'getPriceplansStatus',
  GET_CURRENT_PRICEPLAN_STATUS = 'getCurrentPriceplanStatus',
  GET_APPS_STATUS = 'getAppsStatus',
  GET_NOTICES_STATUS = 'getNoticesStatus',

  GET_STATISTICS_ERRORS = 'getStatisticsErrors',
  GET_ALL_PRODUCTS = 'getAllProducts',
  GET_ALL_KEYWORDS = 'getAllKeywords',
  GET_ALL_CAMPAIGNS = 'getAllCampaigns',
  GET_ALL_PRODUCTS_EXPORT = 'getAllProductsExport',
  GET_ALL_KEYWORDS_EXPORT = 'getAllKeywordsExport',
  GET_ALL_CAMPAIGNS_EXPORT = 'getAllCampaignsExport',
  GET_ALL_PRODUCTS_QUERY_PARAMS = 'getAllProductsQueryParams',
  GET_ALL_KEYWORDS_QUERY_PARAMS = 'getAllKeywordsQueryParams',
  GET_ALL_CAMPAIGNS_QUERY_PARAMS = 'getAllCampaignsQueryParams',

  GET_BILLINGS_CURRENT_PAGE = 'getBillingsCurrentPage',
  GET_BILLINGS_LAST_PAGE = 'getBillingsLastPage',
  GET_BILLINGS_PAGE = 'getBillingsPage',
  GET_BILLINGS_SORT_BY = 'getBillingsSortBy',
  GET_BILLINGS_SORT_TYPE = 'getBillingsSortType',
  GET_BILLINGS_ROWS = 'getBillingsRows',
  GET_BILLINGS_PACKAGE = 'getBillingsPackage',
  GET_BILLINGS_FROM = 'getBillingsFrom',
  GET_BILLINGS_TO = 'getBillingsTo',
  GET_BILLINGS_SEARCH = 'getBillingsSearch',
  GET_BILLINGS_LIMIT = 'getBillingsLimit',
  GET_TOTAL_BILLINGS = 'getTotalBillings',

  GET_TRANSACTIONS_CURRENT_PAGE = 'getTransactionsCurrentPage',
  GET_TRANSACTIONS_LAST_PAGE = 'getTransactionsLastPage',
  GET_TRANSACTIONS_SORT_BY = 'getTransactionsSortBy',
  GET_TRANSACTIONS_SORT_TYPE = 'getTransactionsSortType',
  GET_TRANSACTIONS_PAGE = 'getTransactionsPage',
  GET_TRANSACTIONS_ROWS = 'getTransactionsRows',
  GET_TRANSACTIONS_TYPE = 'getTransactionsType',
  GET_TRANSACTIONS_APPSTORE = 'getTransactionsAppstore',
  GET_TRANSACTIONS_PACKAGE = 'getTransactionsPackage',
  GET_TRANSACTIONS_DATE = 'getTransactionsDate',
  GET_TRANSACTIONS_FROM = 'getTransactionsFrom',
  GET_TRANSACTIONS_TO = 'getTransactionsTo',
  GET_TRANSACTIONS_SEARCH = 'getTransactionsSearch',
  GET_TRANSACTIONS_LIMIT = 'getTransactionsLimit',
  GET_ERRORS_TRANSACTIONS = 'getErrorsTransactions',
  ALL_TRANSACTIONS = 'allTransactions',
  ALL_TRANSACTIONS_EXPORT = 'allTransactionsExport',
  GET_TOTAL_TRANSACTIONS = 'getTotalTransactions',

  ALL_USER_DETAILS_PAYMENTS = 'allUserDetailsPayments',
  GET_USER_DETAILS_PAYMENTS_SEARCH = 'getUserDetailsPaymentsSearch',
  GET_USER_DETAILS_PAYMENTS_ORDER_BY = 'getUserDetailsPaymentsOrderBy',
  GET_USER_DETAILS_PAYMENTS_SORT_BY = 'getUserDetailsPaymentsSortBy',
  GET_USER_DETAILS_PAYMENTS_BALANCE_TYPE = 'getUserDetailsPaymentsBalanceType',
  GET_USER_DETAILS_PAYMENTS_LIMIT = 'getUserDetailsPaymentsLimit',
  GET_TOTAL_USER_DETAILS_PAYMENTS = 'getTotalUserDetailsPayments',
  GET_USER_DETAILS_PAYMENTS_CURRENT_PAGE = 'getUserDetailsPaymentsCurrentPage',
  GET_USER_DETAILS_PAYMENTS_LAST_PAGE = 'getUserDetailsPaymentsLastPage',
  GET_USER_DETAILS_PAYMENTS_PAGE = 'getUserDetailsPaymentsPage',
  GET_USER_DETAILS_PAYMENTS_ROWS = 'getUserDetailsPaymentsRows',

  ALL_USER_DETAILS_REFERRALS = 'allUserDetailsReferrals',
  GET_USER_DETAILS_REFERRALS_SEARCH = 'getUserDetailsReferralsSearch',
  GET_USER_DETAILS_REFERRALS_ORDER_BY = 'getUserDetailsReferralsOrderBy',
  GET_USER_DETAILS_REFERRALS_SORT_BY = 'getUserDetailsReferralsSortBy',
  GET_USER_DETAILS_REFERRALS_LIMIT = 'getUserDetailsReferralsLimit',
  GET_TOTAL_USER_DETAILS_REFERRALS = 'getTotalUserDetailsReferrals',
  GET_USER_DETAILS_REFERRALS_CURRENT_PAGE = 'getUserDetailsReferralsCurrentPage',
  GET_USER_DETAILS_REFERRALS_LAST_PAGE = 'getUserDetailsReferralsLastPage',
  GET_USER_DETAILS_REFERRALS_PAGE = 'getUserDetailsReferralsPage',
  GET_USER_DETAILS_REFERRALS_ROWS = 'getUserDetailsReferralsRows',
  GET_USER_DETAILS_REFERRALS_QUERY_PARAMS = 'getUserDetailsReferralsQueryParams',
  GET_USER_DETAILS_REFERRALS_EXPORT = 'getUserDetailsReferralsExport',

  ALL_USER_DETAILS_TRANSACTIONS = 'allUserDetailsTransactions',
  GET_USER_DETAILS_TRANSACTIONS_SEARCH = 'getUserDetailsTransactionsSearch',
  GET_USER_DETAILS_TRANSACTIONS_ORDER_BY = 'getUserDetailsTransactionsOrderBy',
  GET_USER_DETAILS_TRANSACTIONS_SORT_BY = 'getUserDetailsTransactionsSortBy',
  GET_USER_DETAILS_TRANSACTIONS_TYPE = 'getUserDetailsTransactionsType',
  GET_USER_DETAILS_TRANSACTIONS_LIMIT = 'getUserDetailsTransactionsLimit',
  GET_TOTAL_USER_DETAILS_TRANSACTIONS = 'getTotalUserDetailsTransactions',
  GET_USER_DETAILS_TRANSACTIONS_CURRENT_PAGE = 'getUserDetailsTransactionsCurrentPage',
  GET_USER_DETAILS_TRANSACTIONS_LAST_PAGE = 'getUserDetailsTransactionsLastPage',
  GET_USER_DETAILS_TRANSACTIONS_PAGE = 'getUserDetailsTransactionsPage',
  GET_USER_DETAILS_TRANSACTIONS_ROWS = 'getUserDetailsTransactionsRows',
  GET_USER_DETAILS_TRANSACTION_QUERY_PARAMS = 'getUserDetailsTransactionQueryParams',
  GET_USER_DETAILS_TRANSACTION_EXPORT = 'getUserDetailsTransactionExport',
  GET_USER_DETAILS_APP_LIST = 'getUserDetailsAppList',

  GET_USER_LISTS_COLUMN_SEARCH_FILTER = 'getUserListsColumnSearchFilter',
  GET_USER_LISTS_CURRENT_PAGE = 'getUserListsCurrentPage',
  GET_CURRENT_USER_LIST = 'getCurrentUserList',
  GET_USER_LISTS_LAST_PAGE = 'getUserListsLastPage',
  GET_USER_LISTS_PAGE = 'getUserListsPage',
  GET_USER_LISTS_ROWS = 'getUserListsRows',
  GET_USER_LISTS_SEARCH = 'getUserListsSearch',
  GET_USER_LISTS_LIMIT = 'getUserListsLimit',
  ALL_USER_LISTS = 'allUserLists',
  ALL_USER_LISTS_EXPORT = 'allUserListsExport',
  GET_TOTAL_USER_LISTS = 'getTotalUserLists',
  GET_USER_LISTS_ROLE = 'getUserListsRole',
  GET_USER_LISTS_PRICEPLAN = 'getUserListsPriceplan',
  GET_USER_LISTS_ORDER_BY = 'getUserListsOrderBy',
  GET_USER_LISTS_QUERY_PARAMS = 'getUserListsQueryParams',
  GET_USER_LISTS_SORT_BY = 'getUserListsSortBy',
  GET_CURRENT_USER_ID = 'getCurrentUserId',

  GET_WIDGETS_ERRORS = 'getWidgetsErrors',
  GET_NEWS = 'getNews',
  GET_ALL_GEOS = 'getAllGEOs',
  GET_ALL_TOP_REGIONS = 'getAllTopRegions',
  GET_MY_ALL_APPS = 'getMyAllApps',
  GET_ALL_CAMPAIGNS_WIDGETS = 'getAllCampaignsWidgets',
  GET_ALL_CAMPAIGNS_DATA = 'getAllCampaignsData',
  GET_PINNED_NOTIFICATION_DATA = 'getPinnedNotificationData',

  GET_ADMIN_EMAIL_TEMPLATE_ERROR = 'getAdminEmailTemplateError',
  GET_ADMIN_EMAIL_TEMPLATE_SEARCH = 'getAdminEmailTemplateSearch',
  GET_ADMIN_EMAIL_TEMPLATE_TOTAL = 'getAdminEmailTemplateTotal',

  GET_ADMIN_MARKETING_ACTIONS_CURRENT_PAGE = 'getAdminMarketingActionsCurrentPage',
  GET_ADMIN_MARKETING_ACTIONS_LAST_PAGE = 'getAdminMarketingActionsLastPage',
  GET_ADMIN_MARKETING_ACTIONS_PAGE = 'getAdminMarketingActionsPage',
  GET_ADMIN_MARKETING_ACTIONS_ROWS = 'getAdminMarketingActionsRows',
  GET_ADMIN_MARKETING_ACTIONS_SEARCH = 'getAdminMarketingActionsSearch',
  GET_ADMIN_MARKETING_ACTIONS_LIMIT = 'getAdminMarketingActionsLimit',
  GET_TOTAL_ADMIN_MARKETING_ACTIONS = 'getTotalAdminMarketingActions',
  GET_ADMIN_MARKETING_ACTIONS_ORDER_BY = 'getAdminMarketingActionsOrderBy',
  GET_ADMIN_MARKETING_ACTIONS_SORT_BY = 'getAdminMarketingActionsSortBy',
  GET_ADMIN_MARKETING_ACTIONS_QUERY_PARAMS = 'getAdminMarketingActionsQueryParams',
  GET_ADMIN_MARKETING_ACTIONS_EXPORT_DATA = 'getAdminMarketingActionsExportData',
  GET_ADMIN_MARKETING_ACTIONS_COUNTRY = 'getAdminMarketingActionsCountry',
  GET_ADMIN_MARKETING_ACTIONS_CURRENT_ID = 'getAdminMarketingActionsCurrentId',
  GET_ADMIN_MARKETING_ACTIONS_FILTERS = 'getAdminMarketingActionsFilters',

  GET_ADMIN_PAYMENT_ERROR = 'getAdminPaymentError',
  GET_ADMIN_PAYSYSTEM_SEARCH = 'getAdminPaysystemSearch',
  GET_ADMIN_PAYSYSTEM_TOTAL = 'getAdminPaysystemTotal',

  GET_ADMIN_PRODUCT_ERROR = 'getAdminProductError',
  GET_ADMIN_PRODUCT_TOTAL_PRODUCTS = 'getAdminProductTotalProducts',
  GET_ADMIN_PRODUCT_APPSTORE = 'getAdminProductAppstore',

  GET_ADMIN_REFLINKS_CURRENT_PAGE = 'getAdminReflinksCurrentPage',
  GET_CURRENT_ADMIN_REFLINKS_ID = 'getCurrentAdminReflinksId',
  GET_ADMIN_REFLINKS_LAST_PAGE = 'getAdminReflinksLastPage',
  GET_ADMIN_REFLINKS_PAGE = 'getAdminReflinksPage',
  GET_ADMIN_REFLINKS_ROWS = 'getAdminReflinksRows',
  GET_ADMIN_REFLINKS_SEARCH = 'getAdminReflinksSearch',
  GET_ADMIN_REFLINKS_LIMIT = 'getAdminReflinksLimit',
  GET_TOTAL_ADMIN_REFLINKS = 'getTotalAdminReflinks',
  GET_ADMIN_REFLINKS_CATEGORY = 'getAdminReflinksCategory',
  GET_ADMIN_REFLINKS_ORDER_BY = 'getAdminReflinksOrderBy',
  GET_ADMIN_REFLINKS_SORT_BY = 'getAdminReflinksSortBy',
  GET_ADMIN_REFLINKS_QUERY_PARAMS = 'getAdminReflinksQueryParams',
  GET_ADMIN_REFLINKS_EXPORT_DATA = 'getAdminReflinksExportData',

  GET_ADMIN_USER_APPS_CURRENT_PAGE = 'getAdminUserAppsCurrentPage',
  GET_ADMIN_USER_APPS_COLUMN_SEARCH_FILTER = 'getAdminUserAppsColumnSearchFilter',
  GET_ADMIN_USER_APPS_LAST_PAGE = 'getAdminUserAppsLastPage',
  GET_ADMIN_USER_APPS_PAGE = 'getAdminUserAppsPage',
  GET_ADMIN_USER_APPS_ROWS = 'getAdminUserAppsRows',
  GET_ADMIN_USER_APPS_LIMIT = 'getAdminUserAppsLimit',
  GET_ERRORS_ADMIN_USER_APPS = 'getErrorsAdminUserApps',
  GET_ADMIN_USER_APPS_ORDER_BY = 'getAdminUserAppsOrderBy',
  GET_ADMIN_USER_APPS_SORT_BY = 'getAdminUserAppsSortBy',
  GET_ADMIN_USER_APPS_QUERY_PARAMS = 'getAdminUserAppsQueryParams',
  GET_ADMIN_USER_APPS_EXPORT = 'getAdminUserAppsExport',
  GET_ADMIN_USER_APPS = 'getAdminUserApps',
  GET_ADMIN_USER_APPS_TOTAL = 'getAdminUserAppsTotal',
  GET_ADMIN_USER_APPS_SEARCH = 'getAdminUserAppsSearch',
  GET_ADMIN_USER_APPS_STORE = 'getAdminUserAppsStore',
  GET_ADMIN_USER_APPS_ALL_APPS_FILTER = 'getAdminUserAppsAllAppsFilter',

  GET_USER_ORDERS_COLUMN_SEARCH_FILTER = 'getUserOrdersColumnSearchFilter',
  GET_USER_ORDERS_AFTER_CHANGE_OBJECT = 'getUserOrdersAfterChangeObject',
  GET_USER_ORDERS_COUNT = 'getUserOrdersCount',
  GET_USER_ORDERS_DATA = 'getUserOrdersData',
  GET_TOTAL_USER_ORDERS = 'getTotalUserOrders',
  GET_USER_ORDER_LAST_PAGE = 'getUserOrderLastPage',
  GET_CURRENT_USER_ORDER_PAGE = 'getCurrentUserOrderPage',
  GET_CURRENT_USER_ORDER = 'getCurrentUserOrder',
  GET_DATA_CPI_ACCOUNTS = 'getDataCpiAccounts',
  GET_LOADED_USER_ORDERS = 'getLoadedUserOrders',
  GET_USER_ORDER_PARTNERS = 'getUserOrderPartners',
  GET_UPDATE_USER_ORDER = 'getUpdateUserOrder',
  GET_USER_ORDER_QUERY_PARAMS = 'getUserOrderQueryParams',
  GET_USER_ORDER_EXPORT = 'getUserOrderExport',
  GET_CPI_ACCOUNTS_EXPORT = 'getCpiAccountsExport',
  GET_USER_ORDERS_STATUSES = 'getUserOrdersStatuses',
  GET_FILTER_STATUSES = 'getFilterStatuses',

  GET_USER_PAYMENTS_CURRENT_PAGE = 'getUserPaymentsCurrentPage',
  GET_ADMIN_USER_PAYMENTS_COLUMN_SEARCH_FILTER = 'getAdminUserPaymentsColumnSearchFilter',
  GET_USER_PAYMENTS_LAST_PAGE = 'getUserPaymentsLastPage',
  GET_USER_PAYMENTS_PAGE = 'getUserPaymentsPage',
  GET_USER_PAYMENTS_ROWS = 'getUserPaymentsRows',
  GET_USER_PAYMENTS_SEARCH = 'getUserPaymentsSearch',
  GET_USER_PAYMENTS_LIMIT = 'getUserPaymentsLimit',
  ALL_USER_PAYMENTS = 'allUserPayments',
  GET_TOTAL_USER_PAYMENTS = 'getTotalUserPayments',
  GET_USER_PAYMENTS_ORDER_BY = 'getUserPaymentsOrderBy',
  GET_USER_PAYMENTS_SORT_BY = 'getUserPaymentsSortBy',
  GET_USER_PAYMENTS_PRICEPLAN = 'getUserPaymentsPriceplan',
  GET_USER_PAYMENTS_BALANCE_TYPE = 'getUserPaymentsBalanceType',
  GET_USER_PAYMENTS_PAYMENT_TYPE = 'getUserPaymentsPaymentType',
  GET_USER_PAYMENTS_QUERY_PARAMS = 'getUserPaymentsQueryParams',
  GET_USER_PAYMENTS_EXPORT = 'getUserPaymentsExport',

  GET_ADMIN_VENDORS_CURRENT_PAGE = 'getAdminVendorsCurrentPage',
  GET_CURRENT_VENDORS = 'getCurrentVendors',
  GET_ADMIN_VENDORS_LAST_PAGE = 'getAdminVendorsLastPage',
  GET_ADMIN_VENDORS_PAGE = 'getAdminVendorsPage',
  GET_ADMIN_VENDORS_ROWS = 'getAdminVendorsRows',
  GET_ADMIN_VENDORS_LIMIT = 'getAdminVendorsLimit',
  ALL_ADMIN_VENDORS = 'allAdminVendors',
  GET_TOTAL_ADMIN_VENDORS = 'getTotalAdminVendors',
  GET_ADMIN_VENDORS_ORDER_BY = 'getAdminVendorsOrderBy',
  GET_ADMIN_VENDORS_SORT_BY = 'getAdminVendorsSortBy',
  GET_ADMIN_VENDORS_CURRENT_COUNTRY = 'getAdminVendorsCurrentCountry',
  GET_ADMIN_VENDORS_CURRENT_SELECTED_VENDOR_ID = 'getAdminVendorsCurrentSelectedVendorId',
  GET_ADMIN_VENDORS_CURRENT_STORE = 'getAdminVendorsCurrentStore',
  GET_ADMIN_VENDORS_QUERY_PARAMS = 'getAdminVendorsQueryParams',
  GET_ADMIN_VENDORS_CURRENT_PRODUCT = 'getAdminVendorsCurrentProduct',
  GET_ADMIN_VENDORS_EXPORT = 'getAdminVendorsExport',
  GET_ADMIN_VENDORS_RAW_EXPORT = 'getAdminVendorsRawExport',

  GET_CLIENTS_REPORT_CURRENT_PAGE = 'getClientsReportCurrentPage',
  GET_CLIENTS_REPORT_PRICEPLAN_ID = 'getClientsReportPriceplanId',
  GET_CLIENTS_REPORT_LAST_PAGE = 'getClientsReportLastPage',
  GET_CLIENTS_REPORT_ROWS = 'getClientsReportRows',
  GET_CLIENTS_REPORT_SEARCH = 'getClientsReportSearch',
  GET_CLIENTS_REPORT_LIMIT = 'getClientsReportLimit',
  GET_ERRORS_CLIENTS_REPORT = 'getErrorsClientsReport',
  ALL_CLIENTS_REPORT = 'allClientsReport',
  ALL_CLIENTS_REPORT_EXPORT = 'allClientsReportExport',
  GET_TOTAL_CLIENTS_REPORT = 'getTotalClientsReport',
  GET_CLIENTS_REPORT_ORDER_BY = 'getClientsReportOrderBy',
  GET_CLIENTS_REPORT_QUERY_PARAMS = 'getClientsReportQueryParams',
  GET_CLIENTS_REPORT_SORT_BY = 'getClientsReportSortBy',
  GET_CLIENTS_REPORT_COUNTRY = 'getClientsReportCountry',
  GET_CLIENTS_REPORT_MANAGER = 'getClientsReportManager',
  GET_CLIENTS_REPORT_PAGE = 'getClientsReportPage',

  GET_GOOGLE_ADS_CURRENT_PAGE = 'getGoogleAdsCurrentPage',
  GET_GOOGLE_ADS_TYPE = 'getGoogleAdsType',
  GET_GOOGLE_ADS_FROM_DATE = 'getGoogleAdsFromDate',
  GET_GOOGLE_ADS_TO_DATE = 'getGoogleAdsToDate',
  GET_GOOGLE_ADS_LAST_PAGE = 'getGoogleAdsLastPage',
  GET_GOOGLE_ADS_PAGE = 'getGoogleAdsPage',
  GET_GOOGLE_ADS_ROWS = 'getGoogleAdsRows',
  GET_GOOGLE_ADS_SEARCH = 'getGoogleAdsSearch',
  GET_GOOGLE_ADS_LIMIT = 'getGoogleAdsLimit',
  ALL_GOOGLE_ADS = 'allGoogleAds',
  ALL_GOOGLE_ADS_EXPORT = 'allGoogleAdsExport',
  GET_TOTAL_GOOGLE_ADS = 'getTotalGoogleAds',
  GET_GOOGLE_ADS_ORDER_BY = 'getGoogleAdsOrderBy',
  GET_GOOGLE_ADS_QUERY_PARAMS = 'getGoogleAdsQueryParams',
  GET_GOOGLE_ADS_SORT_BY = 'getGoogleAdsSortBy',
  GET_GOOGLE_ADS_DONT_EXISTS_REF_CODES = 'getGoogleAdsDontExistsRefCodes',

  GET_SALES_REPORT_MY_CLIENTS_CURRENT_PAGE = 'getSalesReportMyClientsCurrentPage',
  GET_SALES_REPORT_MY_CLIENTS_LAST_PAGE = 'getSalesReportMyClientsLastPage',
  GET_SALES_REPORT_MY_CLIENTS_PAGE = 'getSalesReportMyClientsPage',
  GET_SALES_REPORT_MY_CLIENTS_ROWS = 'getSalesReportMyClientsRows',
  GET_SALES_REPORT_MY_CLIENTS_SEARCH = 'getSalesReportMyClientsSearch',
  GET_SALES_REPORT_MY_CLIENTS_LIMIT = 'getSalesReportMyClientsLimit',
  ALL_SALES_REPORT_MY_CLIENTS = 'allSalesReportMyClients',
  ALL_SALES_REPORT_MY_CLIENTS_EXPORT = 'allSalesReportMyClientsExport',
  GET_TOTAL_SALES_REPORT_MY_CLIENTS = 'getTotalSalesReportMyClients',
  GET_SALES_REPORT_MY_CLIENTS_PRICEPLAN = 'getSalesReportMyClientsPriceplan',
  GET_SALES_REPORT_MY_CLIENTS_ORDER_BY = 'getSalesReportMyClientsOrderBy',
  GET_SALES_REPORT_MY_CLIENTS_QUERY_PARAMS = 'getSalesReportMyClientsQueryParams',
  GET_SALES_REPORT_MY_CLIENTS_SORT_BY = 'getSalesReportMyClientsSortBy',
  GET_SALES_REPORT_MY_CLIENTS_OFFER_STATUS = 'getSalesReportMyClientsOfferStatus',

  GET_SALES_REPORT_NEW_CLIENTS_CURRENT_PAGE = 'getSalesReportNewClientsCurrentPage',
  GET_SALES_REPORT_NEW_CLIENTS_LAST_PAGE = 'getSalesReportNewClientsLastPage',
  GET_SALES_REPORT_NEW_CLIENTS_PAGE = 'getSalesReportNewClientsPage',
  GET_SALES_REPORT_NEW_CLIENTS_ROWS = 'getSalesReportNewClientsRows',
  GET_SALES_REPORT_NEW_CLIENTS_LIMIT = 'getSalesReportNewClientsLimit',
  ALL_SALES_REPORT_NEW_CLIENTS = 'allSalesReportNewClients',
  GET_TOTAL_SALES_REPORT_NEW_CLIENTS = 'getTotalSalesReportNewClients',
  GET_SALES_REPORT_NEW_CLIENTS_ORDER_BY = 'getSalesReportNewClientsOrderBy',
  GET_SALES_REPORT_NEW_CLIENTS_SORT_BY = 'getSalesReportNewClientsSortBy',

  GET_SALES_REPORT_STATISTIC_BY_STATUSES = 'getSalesReportStatisticByStatuses',
  GET_SALES_REPORT_STATISTIC_BY_PAYMENTS = 'getSalesReportStatisticByPayments',
  GET_SALES_REPORT_STATISTIC_ERRORS = 'getSalesReportStatisticErrors',
  GET_SALES_REPORT_STATISTIC_QUERY_PARAMS = 'getSalesReportStatisticQueryParams',
  GET_SALES_REPORT_STATISTIC_LAST_PAYMENTS = 'getSalesReportStatisticLastPayments',
  GET_SALES_REPORT_STATISTIC_TOTAL_CLIENTS = 'getSalesReportStatisticTotalClients',
  GET_SALES_REPORT_STATISTIC_LAST_PAYMENTS_CURRENT_PAGE = 'getSalesReportStatisticLastPaymentsCurrentPage',
  GET_SALES_REPORT_STATISTIC_LAST_PAYMENTS_LAST_PAGE = 'getSalesReportStatisticLastPaymentsLastPage',
  GET_SALES_REPORT_STATISTIC_LAST_PAYMENTS_EXPORT = 'getSalesReportStatisticLastPaymentsExportGetter',
  GET_SALES_REPORT_STATISTIC_ACTIVE_CLIENTS = 'getSalesReportStatisticActiveClients',
  GET_SALES_REPORT_STATISTIC_PAYMENTS_COMMITTED = 'getSalesReportStatisticPaymentsCommitted',
  GET_SALES_REPORT_STATISTIC_MY_PAYMENTS_TOTAL = 'getSalesReportStatisticMyPaymentsTotal',
  GET_SALES_REPORT_STATISTIC_LAST_PAYMENTS_TOTAL = 'getSalesReportStatisticLastPaymentsTotal',
  GET_SALES_REPORT_STATISTIC_TOTAL = 'getSalesReportStatisticTotal',

  ALL_USER_DETAILS_MESSAGES = 'allUserDetailsMessages',
  GET_USER_DETAILS_MESSAGES_SEARCH = 'getUserDetailsMessagesSearch',
  GET_TOTAL_USER_DETAILS_MESSAGES = 'getTotalUserDetailsMessages',
  GET_ERRORS_USER_DETAILS_MESSAGES = 'getErrorsUserDetailsMessages',
  GET_USER_DETAILS_MESSAGES_MANAGER = 'getUserDetailsMessagesManager',
  GET_USER_DETAILS_MESSAGES_CAMPAIGN = 'getUserDetailsMessagesCampaign',

  DEACTIVATE_CPI_ACCOUNTS = 'deactivateCpiAccounts',
}


export { Getters };
